
import * as ipxRuntime$DQAxjow0D8 from '/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'
import * as customProviderRuntime$MouMq5cOO1 from '/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/providers/S3Aws.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "nationalhorseman-dev-files.s3.us-west-1.amazonaws.com",
    "api.nationalhorseman.com"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$DQAxjow0D8, defaults: {} },
  ['customProvider']: { provider: customProviderRuntime$MouMq5cOO1, defaults: {"baseURL":"https://nationalhorseman-dev-files.s3.us-west-1.amazonaws.com","modifiers":{"format":"WebP","width":50,"height":50}} }
}
        